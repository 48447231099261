import { BROCHURE_LINK, CONSOLE_LOG } from "./constants";
import { fetchDataFromPHP } from "./module";


function handleSubmit(buttonId, formId) {
    const button = document.getElementById(buttonId);
    if (button) {
        button.addEventListener('click', async () => {
            button.innerHTML = `Submitting <div class="loading-dots"><span class="dot"></span><span class="dot"></span><span class="dot"></span>`
            
            const formState = await processFormInfo(formId);
            if(formState){
                button.innerHTML = 'Send Message';  // Restore button text
                alert("Thank you for submitting your query. We will contact you shortly.");
            } else {
                alert("Something went wrong! Could you please try again later?");
            }
        });
    }
}

async function processFormInfo(formId) {
    const form = document.getElementById(formId);
    if (!form) {
        console.error(`Form with ID ${formId} not found.`);
        return false;
    }

    const inputs = form.querySelectorAll('input, select, textarea');

    let isValid = true; // Assuming this variable is declared outside the loop
    const formData = {};

    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const isRequired = input.getAttribute('data-required') === '1';

        if (isRequired && !input.value) {
            alert(`Please fill out the required field: ${input.placeholder || input.name}`);
            isValid = false;
            break; // Exit the loop immediately
        }

        if (input.value) {
            formData[input.name] = input.value;
        }
    }

    if (!isValid) return false;

    if (CONSOLE_LOG) console.log(formData);
    return await submitFormData(form, formData);
}

async function submitFormData(form, formObject) {
    try {
        const result = await fetchDataFromPHP(1, '/php/contact-us-form-submission.php', formObject);
        if (result) {
            form.reset();
            return true;
        } else {
            if (CONSOLE_LOG) console.log('Form submission failed.');
            return false;
        }
    } catch (err) {
        console.error('There was an error:', err);
        return false;
    }
}

// Initialize event listeners for buttons
handleSubmit('contact-submit-btn', 'contact-us-form');
